body {
  font-family: sans-serif;
}

span {
  text-align: center;
  margin: 20px 0;
  display: block;
}

/*# sourceMappingURL=index.cac2534d.css.map */
